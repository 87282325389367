import classnames from 'classnames/bind';

import Link from 'components/Link';

import styles from './AnnouncementBar.module.css';
let cx = classnames.bind(styles);

const AnnouncementBar = () => {
  return (
    <div className={styles.bar}>
      <Link href={`/account/signup`}>
        Sign Up Today and Get Premium Access for $1!
      </Link>
    </div>
  );
}

export default AnnouncementBar;