import { useState } from 'react';
import { useRouter } from 'next/router';

import styles from './Navbar.module.css';

import SearchIcon from 'public/icons/search.svg';
import Button from 'components/Button';

const SearchInput = ({onSearch}) => {
  const [ value, setValue ] = useState('');
  const router = useRouter();

  const onChange = (e) => {
    setValue(e.target.value);
  }

  const handleKeyDown = (e) => {
    if(e.key === 'Enter') {
      submitSearch();
    }
  }

  const submitSearch = (e) => {
    if(value) {
      router.push(`/all?query=${encodeURIComponent(value)}`);
      if(onSearch) {
        onSearch();
      }
    }
  }

  return (
    <div className={styles['search-wrapper']}>
      <label htmlFor="navbar-search" className={styles['search-icon']}><SearchIcon /></label>
      <input type="text" id="navbar-search" name="search" className={styles['search-input']} value={value} onChange={onChange} onKeyDown={handleKeyDown} placeholder="Search" />
      {/* <Button button submit primary className={styles['search-button']} onClick={submitSearch}>Search</Button> */}
    </div>
  )
}

export default SearchInput