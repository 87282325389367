import { useState } from 'react';
import { useForm } from 'react-hook-form';

import classnames from 'classnames/bind';
import { isofetch } from 'services/fetch';

import styles from './Newsletter.module.css';
let cx = classnames.bind(styles);

import { EmailField } from 'components/Fields';
import Button from 'components/Button';

const Newsletter = ({ title, text, onSubmit }) => {
  const [sent, setSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, formState: { errors, touchedFields } } = useForm();

  const newsletter_classes = cx({
    'newsletter': true,
  });

  const submitForm = async (data) => {
    setIsSubmitting(true);

    // const response = await isofetch(`/api/forms/newsletter`, 'POST', data);

    setIsSubmitting(false);
    setSent(true);
    if(onSubmit) {
      onSubmit();
    }
  }

  return (
    <div className={newsletter_classes}>
      {title && 
        <div className={styles.title}>{title}</div>
      }
      {sent 
      ? <div className={styles.sent}>Thanks for signing up!</div>
      :
      <>
        {text && 
          <div className={styles.text}>{text}</div>
        }
        <form onSubmit={handleSubmit(submitForm)}>
          <EmailField label={`Enter your email address`} name={`email`} register={register} required error={errors.email} show_label={false} light light_margin />
          <Button submit button secondary disabled={isSubmitting} spinner={isSubmitting}>Subscribe</Button>
        </form>
      </>
      }
    </div>
  )
};

export default Newsletter;