import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import styles from './Heading.module.css';
let cx = classnames.bind(styles);

const Heading = ({element, size, lite, extraLite, bold, highlight, children, className, tight, dark, upper, underline, nomargin, center, block}) => {
  const HeadingTag = `${element}`;
  const size_class = size ? size : element;
  const class_name = className ? className : '';

  const classes = cx(
    `${size_class}`,
    `${class_name}`,
    {
      'h1-6': true,
      'highlight': highlight,
      'lite': lite,
      'extra-lite': extraLite,
      'bold': bold,
      'tight': tight,
      'upper': upper,
      'underline': underline,
      'dark': dark,
      'nomargin': nomargin,
      'center': center,
      'block': block
    }
  );

  return (
    <HeadingTag className={classes}>{children}</HeadingTag>
  );
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4-5', 'h4', 'h5', 'h6']),
  lite: PropTypes.bool,
  bold: PropTypes.bool,
  block: PropTypes.bool,
  center: PropTypes.bool,
  highlight: PropTypes.bool,
}

export default Heading;