import classnames from 'classnames/bind';

import styles from './Fields.module.css';
let cx = classnames.bind(styles);

const Radio = ({ options, label, placeholder, name, defaultValue, register, required, nomargin, error }) => {
  const input_classes = cx({
    'input-wrapper': true,
  });

  return (
    <div className={styles['input-wrapper']}>
      <div className={styles['label']}>
        {label}
      </div>

      {options && options.map((option, index) => {
        const option_name = `radio_${name}_${option}`;
        return (
          <div className={styles['checkbox-radio-wrapper']} key={option_name}>
            <input id={option_name} {...register(name, { required: true })} type="radio" value={option} className={styles['checkbox-radio-input']} />
            <label htmlFor={option_name} className={styles['checkbox-radio-label']}>{option}</label>
          </div>
        )
      })}

      {error?.type == 'required' &&
        <div className={styles['error']}>{`${label} is required`}</div>
      }

      {error?.message &&
        <div className={styles['error']}>{error.message}</div>
      }
    </div>
  )
}

export default Radio;