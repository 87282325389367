import { useState } from 'react';
import { createContainer } from 'react-tracked';

const initialAppState = {
  navOpen: false,
  filtersOpen: false,
};

const WbaState = () => useState(initialAppState);

export const {
  Provider: AppProvider,
  useTrackedState: useAppTrackedState,
  useUpdate: useAppSetState
} = createContainer(WbaState);


const initialProfileState = {
  currentProfile: {},
  currentFiters: [],
};

const ProfileState = () => useState(initialProfileState);

export const {
  Provider: ProfileProvider,
  useTrackedState: useProfileTrackedState,
  useUpdate: useProfileSetState
} = createContainer(ProfileState);

