import PropTypes from 'prop-types';

import classnames from 'classnames/bind';

import styles from './Fields.module.css';
let cx = classnames.bind(styles);

const EmailField = ({ label, show_label=true, name, defaultValue, register, required, nomargin, light_margin, light, error }) => {
  const input_classes = cx({
    'input-wrapper': true,
    'nomargin': nomargin,
    'light-margin': light_margin,
    'light': light,
  });

  return (
    <div className={input_classes}>
      {show_label && 
        <label htmlFor={`field_${name}`} className={styles.label}>{label}</label>
      }
      <input id={`field_${name}`} type="email" placeholder={label} defaultValue={defaultValue} {...register(name, { required })} className={styles.input} />
      {error?.type == 'required' &&
        <div className={styles['error']}>{`${label} is required`}</div>
      }

      {error?.message &&
        <div className={styles['error']}>{error.message}</div>
      }
    </div>
  )
}

EmailField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  nomargin: PropTypes.bool,
  error: PropTypes.object,
};

export default EmailField;