import { useEffect, useRef } from 'react';

import classnames from 'classnames/bind';

import styles from './Dropdown.module.css';
let cx = classnames.bind(styles);

import ChevronIcon from 'public/icons/chevron-down.svg';

const Dropdown = ({title, children, open, setOpen, showChevron}) => {
  const ref = useRef();
  const toggleOpen = () => {
    setOpen(!open);
  }

  useEffect(() => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        setOpen(false);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, setOpen]
  );

  const dropdown_classes = cx({
    'dropdown': true,
    'visible': open,
  });

  return (
    <div className={dropdown_classes} ref={ref}>
      <div className={styles.wrapper} onClick={toggleOpen}>
        <div className={styles.title}>
          {title}
        </div>
        {showChevron && <div className={styles.icon}><ChevronIcon /></div>}
      </div>
      <div className={styles.menu}>{children}</div>
    </div>
  );
}



export default Dropdown;