import { useState } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames/bind';

import styles from './Fields.module.css';
let cx = classnames.bind(styles);

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

const CountryRegion = ({ label, name, defaultValue, register, required, nomargin, error, defaultCountry }) => {
  const [country, setCountry] = useState(defaultCountry);
  const [region, setRegion] = useState();

  const selectCountry = (val) => {
    setCountry(val);
  }

  const selectRegion = (val) => {
    setRegion(val);
  }

  const input_classes = cx({
    'input-wrapper': true,
    'input-nomargin': nomargin,
  });

  return (
    <>
      {!defaultCountry &&
        <div className={input_classes}>
          <label htmlFor={`field_country`} className={styles.label}>Country</label>

          <div className={styles['select-wrapper']}>
            <CountryDropdown
              value={country || ''}
              name={`field_country`}
              classes={styles['select']}
              showDefaultOption={true}
              priorityOptions={['US']}
              onChange={(val) => selectCountry(val)} />
          </div>
          
          {error?.type == 'required' &&
            <div className={styles['error']}>{`${label} is required`}</div>
          }

          {error?.message &&
            <div className={styles['error']}>{error.message}</div>
          }
        </div>
      }

      <div className={input_classes}>
        <label htmlFor={`field_region`} className={styles.label}>State/Region</label>

        <div className={styles['select-wrapper']}>
          <RegionDropdown
            country={country || defaultCountry}
            value={region}
            name={`field_region`}
            classes={styles['select']}
            defaultOptionLabel={`Select State/Region`}
            onChange={(val) => selectRegion(val)} />
        </div>

        {error?.type == 'required' &&
          <div className={styles['error']}>{`${label} is required`}</div>
        }

        {error?.message &&
          <div className={styles['error']}>{error.message}</div>
        }
      </div>
    </>
  )
}

CountryRegion.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  nomargin: PropTypes.bool,
  error: PropTypes.object,
};

export default CountryRegion;