import PropTypes from 'prop-types';
import classnames from 'classnames/bind';


import styles from './SocialIcons.module.css';
let cx = classnames.bind(styles);

import Facebook from '../../public/social/facebook.svg';
import LinkedIn from '../../public/social/linkedin.svg';
import Youtube from '../../public/social/youtube.svg';
import Twitter from '../../public/social/twitter.svg';
import Instagram from '../../public/social/instagram.svg';
import Vimeo from '../../public/social/vimeo.svg';

const SocialIcons = ({links}) => {
  const socialIcons = {
    twitter: Twitter,
    facebook: Facebook,
    youtube: Youtube,
    linkedin: LinkedIn,
    instagram: Instagram,
    vimeo: Vimeo,
  };

  return (<>
    {links && 
      <div className={styles['socials']}>
        {Object.keys(links).map((social, index) => {
          if(links[social] && socialIcons[social]) {
            const Icon = socialIcons[social];
            const icon_classes = cx({
              'social-link': true,
              [`social-link-${social}`]: true,
            });

            return (
              <div key={index} className={styles['social']}>
                <a href={links[social]} className={icon_classes}> 
                  <Icon />
                </a>
              </div>
            )
          }
          else {
            return null;
          }
        })}
      </div>
    }
  </>);
}

export default SocialIcons;
