import PropTypes from 'prop-types';

import classnames from 'classnames/bind';

import styles from './Fields.module.css';
let cx = classnames.bind(styles);

const TextField = ({ label, name, defaultValue, register, required, nomargin, error }) => {
  const input_classes = cx({
    'input-wrapper': true,
    'input-nomargin': nomargin,
  });

  return (
    <div className={input_classes}>
      <label htmlFor={`field_${name}`} className={styles.label}>{label}</label>
      <input id={`field_${name}`} placeholder={label} defaultValue={defaultValue} {...register(name, { required })} className={styles.input} />
      {error?.type == 'required' &&
        <div className={styles['error']}>{`${label} is required`}</div>
      }

      {error?.message &&
        <div className={styles['error']}>{error.message}</div>
      }
    </div>
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  nomargin: PropTypes.bool,
  error: PropTypes.object,
};

export default TextField;