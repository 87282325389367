import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import styles from './Modal.module.css';

import CloseIcon from 'public/icons/close.svg';
import WhiteCloseIcon from 'public/icons/close-white.svg';

const ModalWrapper = ({isOpen, setOpen, persistent, children}) => {
  const shadeRef = useRef();
  const shade = {
    hidden: { 
      opacity: 0,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 120,
        default: { duration: 0.42 },
      }
    },
    visible: {
      opacity: 1,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 120,
        default: { duration: 0.42 },
      }
    }
  };

  const modal = {
    hidden: { 
      opacity: 0, 
      y: 50,
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 120,
        default: { duration: 0.42 },
      }
    },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 120,
        default: { duration: 0.42 },
      }
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    if(!persistent && event.target === shadeRef.current) {
      setOpen(false);
    }
  }

  return (
    <AnimatePresence>
      {isOpen &&
        <motion.div initial="hidden" animate="visible" exit="hidden">
          <motion.div
            variants={shade}
            className={styles['modal-wrapper']}
            >
            <motion.div 
              variants={modal}
              className={styles['modal-content-wrapper']}
              onClick={handleClose}
              ref={shadeRef}
            >
              {children}
            </motion.div>
          </motion.div>
        </motion.div>
      }
    </AnimatePresence>
  );
}

const Modal = ({children, isOpen, setOpen, persistent=false}) => {
  const is_server = typeof window === `undefined`;
  if((is_server || !isOpen)) {
    return null;
  }

  return (
    ReactDOM.createPortal(
      <React.Fragment>
        <ModalWrapper isOpen={isOpen} setOpen={setOpen} persistent={persistent}>
          {children}
        </ModalWrapper>
      </React.Fragment>, document.body
    )
  );
}

export const Content = ({children}) => {
  return (
    <div className={styles.content}>
      {children}
    </div>
  )
}

export const CenterContent = ({children}) => {
  return (
    <div className={`${styles['content']} ${styles['content-center']}`}>
      {children}
    </div>
  )
}

export const FullContent = ({children}) => {
  return (
    <div className={styles['content-center']}>
      {children}
    </div>
  )
}

export const Close = ({onClick}) => {
  return (
    <div className={styles.close} onClick={onClick}><CloseIcon /></div>
  );
}

export const WhiteClose = ({onClick}) => {
  return (
    <div className={styles.close} onClick={onClick}><WhiteCloseIcon /></div>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default Modal;