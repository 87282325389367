import { memo } from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import classnames from 'classnames/bind';

import NextLink from 'next/link'

import Spinner from 'components/Spinner';

import styles from './Link.module.css';
let cx = classnames.bind(styles);

const Link = ({href, onClick, primary, className, centered, children, fullHeight, button, underline, highlight, highlightText, dark, header, bold, blank, small, icon, iconOnLeft, inline, large, spacing, block, spinner, disabled}) => {

  const class_name = className ? className : '';
  const classes = cx({
      'link': true,
      'header': header,
      'bold': bold,
      'block': block,
      'btn': button,
      'primary': primary,
      'inline': inline,
      'underline': underline,
      'highlight': highlight,
      'highlight-text': highlightText,
      'dark': dark,
      'large': large,
      'spacing': spacing,
      'full-height': fullHeight,
      'small': small,
      'icon-left': iconOnLeft,
      'spinning': spinner,
      'disable': disabled,
    },
    `${class_name}`
  );

  const CenterWrapper = ({children}) => {
    return (
    <div className={`${styles['wrapper']} ${styles['center']}`}>
      {children}
    </div>
    )
  }

  const NormalLink = ({children}) => {
    let other_attributes = {};

    if(blank) {
      other_attributes.target = '_blank';
    }
    return (
      <NextLink href={href}>
        <a className={classes} {...other_attributes}>
          {children}
          {icon && <div className={styles['icon-wrapper']}>{icon}</div>}
        </a>
      </NextLink>
    )
  }

  const NormalButton = ({children}) => {
    return (
      <div onClick={onClick} className={classes}>
        {children}
        {icon && <div className={styles['icon-wrapper']}>{icon}</div>}
        {spinner &&
          <div className={styles['spinner']}>
            <Spinner visible={true} />
          </div>
        }
      </div>
    )
  }

  const Wrapper = ({children}) => {
    if(href) {
      if(centered) {
        return (
          <CenterWrapper>
            <NormalLink>{children}</NormalLink>
          </CenterWrapper>
        );
      }
      else {
        return <NormalLink>{children}</NormalLink>;
      }
    }
    else {
      if(centered) {
        return <NormalButton>{children}</NormalButton>;
      }
      else {
        return (
          <CenterWrapper>
            <NormalButton>{children}</NormalButton>
          </CenterWrapper>
        )
      }
    }
  }

  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

Link.propTypes = {
  children: requiredIf(PropTypes.node, props => !props.icon),
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  header: PropTypes.bool,
  centered: PropTypes.bool,
  button: PropTypes.bool,
  large: PropTypes.bool,
  fullHeight: PropTypes.bool,
  highlight: PropTypes.bool,
  highlightText: PropTypes.bool,
  underline: PropTypes.bool,
  blank: PropTypes.bool,
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  iconOnLeft: PropTypes.bool,
}

export default memo(Link);