import React, { useState, useEffect, useRef, memo } from 'react';
import classnames from 'classnames/bind';
import { useSWRConfig } from 'swr';

import Image from 'next/image';
import { motion } from 'framer-motion';

import { useRouter } from 'next/router';
import { useAppSetState, useAppTrackedState } from 'components/AppLayout/store';

import { useUser } from 'services/user';
import { useProfile } from 'services/profile';

import Button from 'components/Button';
import SearchInput from 'components/Navbar/SearchInput';
import Dropdown from 'components/Dropdown';

import styles from './Navbar.module.css';
const cx = classnames.bind(styles);

import Close from 'public/icons/close.svg';
import MenuIcon from 'public/icons/menu.svg';


const LogoImage = memo(function LogoImage({ src }) {
  return (
    <Image
      src={src}
      alt={'Walbeck Baseball Academy'}
      height="164"
      layout="responsive"
      quality="90"
      width="194"
      key="navbar-logo"
      priority={true}
    />
  )
});

const SeconadryLogoImage = memo(function SecondaryLogoImage({ src }) {
  return (
    <Image
      src={src}
      alt={'Walbeck Baseball Academy'}
      height="69"
      layout="responsive"
      quality="90"
      width="194"
      key="navbar-logo"
      priority={true}
    />
  )
});

const Logo = memo(function Logo({secondary, user}) {
  return (
    <Button href={user ? `/dugout` : `/`}>
      {secondary
      ? <SeconadryLogoImage src="/walbeck-logo-text.png" />
      : <LogoImage src="/walbeck-logo.png" />
      }
    </Button>
  )
});

const MenuWrapper = ({children}) => {
  const setState = useAppSetState();
  const state = useAppTrackedState();

  const closeNav = () => {
    setState((prev) => ({...prev, navOpen: false}));
  }

  const shadeRef = useRef();

  const shade = {
    closed: { 
      opacity: 0,
      x: '100vw',
      pointerEvents: 'none',
    },
    open: {
      opacity: 1,
      x: '0vw',
      transition: {
        type: "spring",
        damping: 25,
        stiffness: 10,
        bounce: 0.25,
        default: { duration: 0.42 },
      },
      pointerEvents: 'auto',
    }
  };
  
  const handleClose = (event) => {
    event.preventDefault();
    if(event.target === shadeRef.current) {
      closeNav();
    }
  }

  return (
    <motion.div initial="closed" animate={(state && state.navOpen) ? "open" : "closed"} exit="closed" className={styles.menu}>
      <motion.div
        variants={shade}
        className={styles.wrapper}
        >
          <div className={styles['modal-close']} onClick={closeNav}><Close/></div>
          {children}
      </motion.div>
    </motion.div>
  );
}

const MenuItem = ({title, href, active}) => {
  const setState = useAppSetState();

  const closeNav = () => {
    setState((prev) => ({...prev, navOpen: false}));
  }

  const items = {
    open: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 120,
        velocity: 1,
        damping: 20, 
      }
    },
    closed: {
      x: 75,
      opacity: 0,
      transition: {
        stiffness: 100
      }
    }
  };

  const handleClick = () => {
    closeNav();
  }

  return (
    <div className={styles.item}>
      <Button href={href} highlightText={active} large bold>{title}</Button>
    </div>
  )
}

const Menu = () => {
  const { user, isLoading } = useUser();
  const router = useRouter();
  const setState = useAppSetState();
  const { cache } = useSWRConfig();

  const closeNav = () => {
    setState((prev) => ({...prev, navOpen: false}));
  }

  const signout = async () => {
    await cache.clear();
    window.location.href = `/api/auth/logout`;
  }

  const variants = {
    open: {
      transition: { staggerChildren: 0.09, delayChildren: 0.15 }
    },
    closed: {
      transition: { staggerChildren: 0.1, staggerDirection: -1 }
    }
  };

  const items = {
    open: {
      opacity: 1,
      transition: {
        delay: 0.6
      }
    },
    closed: {
      opacity: 0,
    }
  };


  const signedOutItems = [
    {
      title: 'Home',
      href: '/',
      active: true,
    },
    {
      title: 'My Dugout',
      href: '/dugout',
    },
    {
      title: 'All Videos',
      href: '/all',
    },
    {
      title: 'About Us',
      href: '/about',
    },
    {
      title: 'Contact',
      href: '/contact',
    },
  ]
  
  const signedInItems = [
    {
      title: 'My Dugout',
      href: '/dugout',
    },
    {
      title: 'All Videos',
      href: '/all',
    },
    {
      title: 'Account',
      href: '/account',
    },
    {
      title: 'Contact',
      href: '/contact',
    },
    {
      title: 'About Us',
      href: '/about',
    }
  ];

  const menuItems = (!isLoading && user) ? signedInItems : signedOutItems;

  const submenuItems = [
    {
      title: 'True Temper Bats',
      href: '/bat-fittings',
    },
    {
      title: 'Store',
      href: 'https://walbeckbaseball.itemorder.com/sale',
      blank: true,
    },
    {
      title: 'Apply To Train With Coach Walbeck',
      href: '/train-with-matt',
    },
  ];

  return (
    <MenuWrapper>
      <motion.div variants={variants} className={styles['nav-wrapper']}>
        <div className={styles['menu-nav']}>
          {menuItems.map((item, index) => (
            <MenuItem key={index} {...item} active={router.pathname == item.href} />
          ))}
        </div>

        {!user &&
          <>
            <div className={styles.ctas}>
              <Button href="/account/signup" button primary block centered>Sign Up Today!</Button>
              <Button href={`/api/auth/login`} underline small centered>Sign In</Button>
            </div>
          </>
        }


        <div className={styles.search}>
          <SearchInput onSearch={closeNav} />
        </div>

        <div className={styles['submenu-nav']}>
          {submenuItems.map((item, index) => (
            <Button href={item.href} key={index} {...(item.blank ? {blank: true} : {})} className={styles['submenu-link']}>{item.title}</Button>
          ))}
        </div>



        {user && 
          <Button onClick={signout} underline centered className={styles.signout}>Sign Out</Button>
        }

        <div className={styles.footer}>
          <Button href="/wba-privacy-policy.pdf" small blank className={styles['footer-privacy']}>Priacy Policy</Button>
          <Button href="/wba-terms-of-service.pdf" small blank className={styles['footer-terms']}>Terms &amp; Conditions</Button>
        </div>
      </motion.div>
    </MenuWrapper>
  )
}

const Profile = () => {
  const [open, setOpen] = useState(false);
  const { profiles, current_profile } = useProfile();
  const { cache } = useSWRConfig();

  // const { mutate } = useSWRConfig();

  // useEffect(() => {
  //   const get_profiles = async () => {
  //     await mutate(`/api/fm/profile`);
  //   }

  //   get_profiles();
  // }, []);

  if(!profiles || profiles.length === 0) { return null; }

  const ProfileAvatar = () => {
    return (
      <div className={styles['acronym-wrapper']}>
        <div className={styles.acronym}>{current_profile && current_profile.acronym || ''}</div>
      </div>
    )
  }

  const handleProfileSelect = async (new_profile) => {
    setOpen(false);
    await cache.clear();
    window.location.href = `/api/change-profile?id=${new_profile.id}`;
  }

  return (
    <div className={styles.profile}>
      <Dropdown title={<ProfileAvatar />} open={open} setOpen={setOpen} showChevron>
        <div className={styles['profile-menu']}>
          <div className={styles['profile-option']}>
            <Button href={`/dugout`} className={styles['profile-item']}>My Dugout</Button>
          </div>
          {current_profile && 
            <div className={`$styles['profile-option'] $styles['profile-option-current']`}>
              <div className={styles['profile-item']}>
                <div className={styles['profile-avatar']}>
                  <div className={styles['profile-avatar-wrapper']}>
                  {current_profile.acronym}
                  </div>
                </div>
                <div className={styles['profile-name']}>
                  {current_profile.name}
                </div>
              </div>
            </div>    
          }
          {profiles && 
            profiles.map((profile, index) => {
              if(current_profile && current_profile.id == profile.id) {
                return null;
              }

              return (
                <div 
                  className={styles['profile-option']}
                  key={`profile${index}`}
                  onClick={() => handleProfileSelect(profile)}
                >
                  <div className={styles['profile-item']}>
                    <div className={styles['profile-avatar']}>
                      <div className={styles['profile-avatar-wrapper']}>
                      {profile.acronym}
                      </div>
                    </div>
                    <div className={styles['profile-name']}>
                      {profile.name}
                    </div>
                  </div>
                </div>
              );
            })
          }
          <div className={styles['profile-option']}>
            <Button href={`/account/profiles`} className={styles['profile-item']}>Manage Profiles</Button>
          </div>
        </div>
      </Dropdown>
    </div>
  );
}

const Navbar = ({secondary, staticNav, navCta = false}) => {
  const setState = useAppSetState();
  const { user, isLoading } = useUser();

  const openNav = () => {
    setState((prev) => ({...prev, navOpen: true}));
  }

  const classes = cx({
    navbar: true,
    secondary: secondary,
    static: staticNav
  });

  return (
    <div className={classes}>
      <div className={styles['navbar-container']}>
        <div className={styles.logo}>
          <Logo secondary={secondary} user={user} />
        </div>
        <div className={styles.actions}>
          {!isLoading && user && <Profile />}
          {navCta &&
            <Button href={`/account/signup`} small button secondary>Start Your Free Trial</Button>
          }
          <div className={styles.toggle} onClick={openNav}>
            <MenuIcon />
          </div>
        </div>
        <Menu />
      </div>
    </div>
  )
}

export default Navbar;