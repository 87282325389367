import React from 'react';
import Image from 'next/image';
import classnames from 'classnames/bind';

import SocialIcons from 'components/SocialIcons';

import styles from './Footer.module.css';
import Newsletter from 'components/Newsletter';
const cx = classnames.bind(styles);

const Logo = React.memo(function Logo({ src }) {
  return (
    <Image
      src={src}
      alt={'Walbeck Baseball Academy'}
      height="164"
      layout="responsive"
      quality="90"
      width="194"
      key="navbar-logo"
      priority={true}
    />
  )
});

const social_links = {
  twitter: "https://twitter.com/WalbeckWBA",
  facebook: "https://www.facebook.com/walbeckbaseballacademy/",
  youtube: "https://www.youtube.com/channel/UClscJgQu_6Sj1ggKQxuihoA",
  vimeo: "https://vimeo.com/user13485245",
  instagram: "https://www.instagram.com/walbeckbaseballacademy/",
};

function Footer({secondary=false, basic}) {
  const classes = cx({
    footer: true,
    secondary: secondary,
    basic: basic,
  })

  return (
    <div className={classes}>
      <div className="container">
        <div className={styles.wrapper}>
          <div className={styles.content}>

            <div className={styles.title}>Walbeck Baseball Academy</div>
            <div className={styles.text}>Improve your baseball fundamentals with 12-year MLB veteran, MLB Coach Matt Walbeck. We offer online training for athletes around the world.</div>

            <div className={styles['newsletter-mobile']}>
              <Newsletter title={`Join Our Newsletter`} text={`Get updates on our newest training content.`} />
            </div>


            <div className={styles.icons}><SocialIcons links={social_links} /></div>
            <div className={styles.copyright}><SocialIcons /></div>
          </div>
          
          <div className={styles.newsletter}>
            <Newsletter title={`Join Our Newsletter`} text={`Get updates on our newest training content.`} />
          </div>
            <div className={styles.logo}>
              <Logo src="/walbeck-logo.png" />
            </div>
          </div>
      </div>
    </div>
  );
}

export default Footer;