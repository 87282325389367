import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import classnames from 'classnames/bind';

import NextLink from 'next/link'

import Spinner from 'components/Spinner/Spinner';

import styles from './Button.module.css';
let cx = classnames.bind(styles);

const Button = ({href, onClick, primary, secondary, dark, darkgray, className, centered, children, fullHeight, button, submit, underline, highlight, highlightText, whiteText, inheritText, header, bold, lite, blank, small, wide, hoverWide, icon, iconOnLeft, inline, large, spacing, nomargin, block, squared, pill, spinner, disabled, prefetch, shallow, external}) => {

  const class_name = className ? className : '';
  const classes = cx(
    `${class_name}`,
    {
    'cta': true,
    'header': header,
    'bold': bold,
    'lite': lite,
    'squared': squared,
    'pill': pill,
    'block': block,
    'btn': button,
    'primary': primary,
    'secondary': secondary,
    'inline': inline,
    'underline': underline,
    'highlight': highlight,
    'highlight-text': highlightText,
    'white-text': whiteText,
    'inherit-text': inheritText,
    'dark': dark,
    'darkgray': darkgray,
    'large': large,
    'spacing': spacing,
    'nomargin': nomargin,
    'full-height': fullHeight,
    'small': small,
    'wide': wide,
    'hover-wide': hoverWide,
    'icon': icon,
    'icon-left': iconOnLeft,
    'spinner': spinner,
    'disable': disabled,
    }
  );

  const CenterWrapper = ({children}) => {
    if(centered) {
      return (
      <div className={`${styles['wrapper']} ${styles['center']}`}>
        {children}
      </div>
      )
    }
    else {
      return children;
    }
  }

  const NormalLink = ({children}) => {
    let other_attributes = {};

    if(blank) {
      other_attributes.target = '_blank';
    }

    if(external) {
      return ( <div onClick={onClick}><a href={href} className={classes} {...other_attributes}>{children}</a></div> )
    }

    const RefLink = forwardRef(({ onClick, href }, ref) => {
      return (
        <a href={href} onClick={onClick}  className={classes} {...other_attributes}>{children}</a>
      )
    });
    RefLink.displayName = 'RefLink';

    return (
      <NextLink href={href} prefetch={prefetch} shallow={shallow} onClick={onClick} passHref>
        <RefLink />
      </NextLink>
    )
  }

  const NormalButton = ({children}) => {
    return (
      <button {...(submit ? {type: 'submit'} : {})} onClick={onClick} className={classes}>
        {children}
        {icon && <div className={styles['icon-wrapper']}>{icon}</div>}
        {spinner &&
          <div className={styles['spinner']}>
            <Spinner visible={true} />
          </div>
        }
      </button>
    )
  }

  const Wrapper = ({children}) => {
    if(href) {
      return <NormalLink>{children}</NormalLink>;
    }
    else {
      return <NormalButton>{children}</NormalButton>;
    }
  }

  return (
    <CenterWrapper>
      <Wrapper>
        {children}
      </Wrapper>
    </CenterWrapper>
  );
}

Button.propTypes = {
  children: requiredIf(PropTypes.node, props => !props.icon),
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
  header: PropTypes.bool,
  centered: PropTypes.bool,
  button: PropTypes.bool,
  submit: PropTypes.bool,
  large: PropTypes.bool,
  fullHeight: PropTypes.bool,
  highlight: PropTypes.bool,
  highlightText: PropTypes.bool,
  underline: PropTypes.bool,
  blank: PropTypes.bool,
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  iconOnLeft: PropTypes.bool,
}

export default Button;