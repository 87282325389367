import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { useWatch, setValue } from 'react-hook-form';

import classnames from 'classnames/bind';

import styles from './Fields.module.css';
let cx = classnames.bind(styles);

function CharLimit({ control, name }) {
  const firstName = useWatch({
    control,
    name: name
  });

  return <div>{firstName}</div>; // only re-render at the component level, when firstName changes
}

const TextareaField = ({ control, label, name, rows, cols, limit, defaultValue, register, required, nomargin, error }) => {
  const [content, setContent] = useState('');
  

  const input_classes = cx({
    'input-wrapper': true,
  });

  const setFormattedContent = useCallback(
    text => {
      if(limit) {
        setContent(text.slice(0, limit));
      }
      else {
        setContent(text);
      }
    },
    [limit, setContent]
  );

  return (
    <div className={input_classes}>
      <label htmlFor={`field_${name}`} className={styles.label}>{label}</label>
      <textarea
        id={`field_${name}`}
        rows={rows}
        cols={cols}
        onChange={event => {
          setFormattedContent(event.target.value)
        }}
        {...register(name, { required, maxLength: limit })}
        className={styles.input} 
      />
      {limit && content &&
        <div className={styles['field-textarea-limit']}>
          {content.length}/{limit}
        </div>
      }

      {error?.type == 'required' &&
        <div className={styles['error']}>{`${label} is required`}</div>
      }

      {error?.message &&
        <div className={styles['error']}>{error.message}</div>
      }
    </div>
  )
}

TextareaField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  register: PropTypes.func,
  cols: PropTypes.number,
  rows: PropTypes.number,
  limit: PropTypes.number,
  required: PropTypes.bool,
  nomargin: PropTypes.bool,
  error: PropTypes.object,
};


export default TextareaField;