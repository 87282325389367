import { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import { AppProvider, ProfileProvider } from './store';

import styles from './AppLayout.module.css';
const cx = classnames.bind(styles);

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import NewsletterModal from 'components/Modal/NewsletterModal';
import AnnouncementBar from 'components/AnnouncementBar';


const AppLayout = ({children, staticNav=false, secondary=false, basicFooter=true, navCta=false, light=false, ...props}) => {
  const classes = cx({
    content: true,
    light: light
  });

  useEffect(() => {
    if(light) {
      document.body.setAttribute('data-light', '');
    }
    else {
      document.body.removeAttribute('data-light');
    }
  }, [light]);

  return (
    <AppProvider {...props}>
      <ProfileProvider {...props}>
        {/* <AnnouncementBar /> */}
        <div className={classes}>
          <Navbar secondary={secondary} staticNav={staticNav} navCta={navCta} />
          <div className={(!basicFooter) ? styles['page-content'] : undefined}>{children}</div>
          <Footer secondary={secondary} basic={basicFooter} />
          {/* <NewsletterModal /> */}
        </div>
      </ProfileProvider>
    </AppProvider>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppLayout;
