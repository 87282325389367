import styles from './Fields.module.css';

import ChevronDown from 'public/icons/chevron-down.svg';

const Select = ({ options, label, placeholder, name, defaultValue, register, required, nomargin, error }) => {
  return (
    <div className={styles['input-wrapper']}>
      <label htmlFor={`field_${name}`} className={styles.label}>{label}</label>

      <div className={styles['select-wrapper']}>
          <select id={`field_${name}`} className={styles['select']} defaultValue={defaultValue} {...register(name, { required })}>
            <option value="" label={placeholder} className={styles['option']} />
            {options && options.map((option, index) => {
              return (
                <option key={index} value={option.value} className={styles['option']}>{option.label}</option>
              )
            })}
          </select>
          {/* <label htmlFor={`field_${name}`} className={styles['select-down-arrow']}>
            <ChevronDown />
          </label> */}
      </div>

      {error?.type == 'required' &&
        <div className={styles['error']}>{`${label} is required`}</div>
      }

      {error?.message &&
        <div className={styles['error']}>{error.message}</div>
      }
    </div>
  )
}

export default Select; 